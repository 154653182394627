// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/@kbs/formiojs-custom/dist/main.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,body{height:100%}.formio-form-custom-theme{height:100%;overflow-y:auto;padding-bottom:150px}.formio-form-custom-theme .form-field-type-panel>.panel .panel-body{overflow-x:auto}.multi-page-form .formio-form-custom-theme{overflow-y:hidden;padding-bottom:0px}.multi-page-form .wizard-form-body{padding-bottom:150px}@media(min-height: 640px){body.single-page-form{padding-bottom:51px}.formio-component.formio-component-submit{position:fixed;bottom:0;width:100%;background-color:#fff;padding:0px 13px}}", "",{"version":3,"sources":["webpack://./client/app/formio4.scss"],"names":[],"mappings":"AAEA,UACI,WAAA,CAEF,0BACI,WAAA,CACA,eAAA,CACA,oBAAA,CAEJ,oEACE,eAAA,CAGA,2CACE,iBAAA,CACA,kBAAA,CAEF,mCACE,oBAAA,CAIJ,0BACE,sBAEE,mBAAA,CAEF,0CACE,cAAA,CACA,QAAA,CACA,UAAA,CACA,qBAAA,CACA,gBAAA,CAAA","sourcesContent":["@import '@kbs/formiojs-custom/dist/main.css';\n\nhtml, body {\n    height: 100%\n  }\n  .formio-form-custom-theme {\n      height: 100%;\n      overflow-y: auto;\n      padding-bottom: 150px;\n  }\n  .formio-form-custom-theme .form-field-type-panel > .panel .panel-body {\n    overflow-x: auto;\n  }\n  .multi-page-form {\n    .formio-form-custom-theme {  \n      overflow-y: hidden;\n      padding-bottom: 0px;\n    }\n    .wizard-form-body {\n      padding-bottom: 150px;\n    }\n  }\n  /* Fxed position submit buttons inside iframe */\n  @media (min-height: 640px) {\n    body.single-page-form {\n      /* Give space to fixed submission button */\n      padding-bottom: 51px;\n    }\n    .formio-component.formio-component-submit {\n      position: fixed;\n      bottom: 0;\n      width: 100%;\n      background-color: #fff;\n      padding: 0px 13px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
